import axiosInstance from '.';

// Функция для регистрации компании
export const registerCompany = async (data) => {
  try {
    const response = await axiosInstance.post('/api/companies', data);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для обновления компании
export const updateCompany = async (data) => {
  try {
    const response = await axiosInstance.put('/api/companies', data);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// функция для удаления компании
export const deleteCompany = async (id) => {
  try {
    const response = await axiosInstance.delete(`/api/companies/${id}`);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// функция обновления кастомера в компании
export const updateCustomerInCompany = async (data) => {
  try {
    const response = await axiosInstance.post('/api/companies/updateCustomer', data);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// функция удаления кастомера в компании
export const deleteCustomerInCompany = async (customerId) => {
  try {
    const response = await axiosInstance.post(`/api/companies/removeCustomer/${customerId}`);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// функция добавления кастомера в компании
export const addCustomerInCompany = async (data) => {
  try {
    const response = await axiosInstance.post('/api/companies/customerAdd', data);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для логина компании
export const authLoginCompany = async ({ email, password }) => {
  try {
    const response = await axiosInstance.post('/api/companies/auth', { login: email, password });
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для приглашения пользователя
export const inviteCustomer = async (data) => {
  const requestData = {
    // startDate: oneYearAgo.toISOString(),
    // endDate: currentDate.toISOString(),
    // date: currentDate.toISOString(),
    // idCompany: globalController.getData()?.companyId,
    customerId: null,
  };

  try {
    const response = await axiosInstance.post('/api/customers/invitation', {
      ...requestData,
      ...data,
    });
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для проверки приглашения пользователя
export const inviteCustomerCheck = async (code) => {
  try {
    const response = await axiosInstance.post(`/api/customers/invitation/check/${code}`);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};
