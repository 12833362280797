import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import globalController from '../../mobx/GlobalController'
import Onboarding from '../Onboarding/Onboarding';
import {steps} from '../../utils/onboardingSteps'

export interface OnboardingTour {
    run: boolean;
    activeStepIndex: number;
    steps: any[];
    passedSteps: any[];
    tourLength: number;
    tourActive: boolean;
  }
  

const OnboardingWrapper = observer(() => {
  const location = useLocation();
  const [onboardingState, setOnboardingState] = useState<OnboardingTour | undefined>(undefined)
  const [init, setInit] = useState(false)

  

const unPasseStepsFinder = (arr: number[]): number => {
    let passedSteps: number[] | undefined = onboardingState?.passedSteps;
    let steps: number[] = arr;

    if (passedSteps === undefined) {
        passedSteps = []; // если passedSteps не определено, используем пустой массив
    }

    let filteredSteps = steps.filter(num => !passedSteps!.includes(num));

    if (filteredSteps.length) {
        return Math.min(...filteredSteps);
    } else {
        return -1;
    }
}


const updateOnboardingState = (changes: Partial<OnboardingTour>) => {
  setOnboardingState(prevState => ({
    ...prevState!,
    ...changes
        }));
      };

  useEffect(() => {
    const onboardingStateString = localStorage.getItem('onboardingTourState')
    console.log('Onboarding init')

    

    if(!onboardingStateString) {
        const initialOnboardingTourState:OnboardingTour = {
            
            run: false,
            activeStepIndex: 0,
            steps: steps,
            passedSteps: [],
            tourLength: steps.length,
            tourActive: true
        }

        setOnboardingState(initialOnboardingTourState)
        setInit(true)
    } else {
        setOnboardingState(JSON.parse(onboardingStateString))
        setInit(true)
    }

  }, [])

  useEffect(()=>{
    localStorage.setItem('onboardingTourState', JSON.stringify(onboardingState));

  }, [onboardingState])

  // Watch modal AddEmployee to stop or contuniu tour
  useEffect(()=>{
    if(!onboardingState?.tourActive) return

    if(globalController.modals.addEmployee === true && onboardingState?.activeStepIndex === 6) {
      updateOnboardingState({run: false, activeStepIndex: 7})
  
      return
    }
  
    if(globalController.modals.addEmployee === false && onboardingState?.activeStepIndex === 7) {
      updateOnboardingState({run: true, activeStepIndex: 7})
  
      return
    }
  }, [globalController.modals.addEmployee])

  // Watch self modal
  useEffect(()=>{
    if(!onboardingState?.tourActive) return

    if(globalController.modals.self === true && onboardingState?.activeStepIndex === 14) {
      updateOnboardingState({run: false, activeStepIndex: 15})
  
      return
    }
  
    if(globalController.modals.self === false && onboardingState?.activeStepIndex === 15) {
      updateOnboardingState({run: true, activeStepIndex: 15})
  
      return
    }
  }, [globalController.modals.self])

  // Watch setting of an employee
  useEffect(()=>{
    console.log('Should switch the step')
    if(!onboardingState?.tourActive) return

    if( onboardingState?.activeStepIndex === 7) {
      updateOnboardingState({run: true, activeStepIndex: 8})
  
      return
    }

  }, [globalController.employee])

  function onboardingController(activeStep:number, workingSteps:number[]) {
    if(activeStep === workingSteps[0]) {
      updateOnboardingState({ run: true });
    } else {
      let step = unPasseStepsFinder(workingSteps);

      if (step === -1) {
        updateOnboardingState({ run: false });
      } else {
        updateOnboardingState({ run: true, activeStepIndex: step });
      }

      return
    } 
    
    if(workingSteps.slice(-1)[0] < activeStep) {
      updateOnboardingState({ run: false });
    } 
      
  }

  useEffect(() => {  
    if (!onboardingState) return
    if(!onboardingState.tourActive) return
  
    const { activeStepIndex } = onboardingState;
    let workingSteps = []
  
    switch (location.pathname) {
      case '/':
        workingSteps = [0,1]

        onboardingController(activeStepIndex, workingSteps)

        if (activeStepIndex < 1) {
          const step = unPasseStepsFinder(workingSteps);
          updateOnboardingState({ run: true, activeStepIndex: step });
        }
        
        break;
  
      case '/register':
        workingSteps = [2, 3]
        onboardingController(activeStepIndex, workingSteps)

        break;
  
      case '/stats':

        workingSteps = [4, 5, 16, 17, 18, 19]
        // Обязательные шаги 0-5
        const mandatorySteps = [0, 1, 2, 3];
        // Объединяем массивы, исключая дублирование
        const newPassedSteps = Array.from(new Set([...onboardingState.passedSteps, ...mandatorySteps]));

        onboardingController(activeStepIndex, workingSteps)

        break;
      
      case '/search':
        workingSteps = [6, 7, 8]
        onboardingController(activeStepIndex, workingSteps)

        break;

      case '/profile':
      workingSteps = [9, 10, 11]
      onboardingController(activeStepIndex, workingSteps)

      break;

      case '/scores':
      workingSteps = [12]
      onboardingController(activeStepIndex, workingSteps) 

      break;

      case '/education':
      workingSteps = [13]
      onboardingController(activeStepIndex, workingSteps) 

      break;

      case '/self':
      workingSteps = [14, 15]
      onboardingController(activeStepIndex, workingSteps) 

      break;

      case '/help':
        workingSteps = [20, 21, 22]
        onboardingController(activeStepIndex, workingSteps)

        break;

      case '/payments':
        workingSteps = [23,24]
        onboardingController(activeStepIndex, workingSteps)

        break;
  
      default:
        if (activeStepIndex === 0 && location.pathname !== '/') {
          updateOnboardingState({ run: false });
        }
        break;
          
    }
  }, [location.pathname, init]);

  // if(!init) {
  //   return null
  // }

  const currentSteps = onboardingState?.steps ?? steps; // Если steps в onboardingState не определен, используем значение по умолчанию steps
  const currentRun = onboardingState?.run ?? false; // Если run в onboardingState не определен, используем false
  const currentStepIndex = onboardingState?.activeStepIndex ?? 0; // Если activeStepIndex в onboardingState не определен, используем 0

  return (
    <Onboarding steps={currentSteps} run={currentRun} stepIndex={currentStepIndex} setOnboardingState={setOnboardingState} />
  );
})

export default OnboardingWrapper;