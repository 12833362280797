import React, { useState } from "react"
import PageTitle from "../../../components/PageTitle"
import PageBlock, { PageBlockTitle } from "../../../components/PageBlock"
import Divider from "../../../components/Divider"
import Circles from "./Circles"
import ColorLines, { colors } from "./ColorLines"
import { mockData } from "./mockData"
import CirclesThin from "./CirclesThin"
import PeriodTag from "../../User/MyScores/PeriodTag"
import CalendarIcon from "../../../components/Icons/CalendarIcon"
import Button from "../../../components/Button"
import Points from "./Points"
import Select from "../../../components/Select/Select"
import { RateChange } from "../../User/MyScores/RateChange"
import Chart from "../../User/MyScores/Chart"
import { iconsData } from "../../User/MyScores/icons"
import { getMockDataTotal } from "../../User/MyScores/mockData"
import { labels } from "../../User/MyScores"
import { mockData as mockDataChart } from "../../User/MyScores/mockData"
import Selector from "../../../components/Selector/Selector"
import EmployeePerfomanceGraph from "./EmployeePerfomanceGraph"
import EmployeeSkillsGraph from "./EmployeeSkillsGraph"
import {
	barDatasets,
	barOptions,
	chart500,
	chart5years,
	chartES,
	labelsDays,
} from "./mockCharts"
import {
	Bank,
	Employerscore,
	FirstAid,
	PoliceCar,
	Syringe,
	User,
} from "../../../components/Icons/Scores"
import graphData from "./EmployeePerfomanceData"
import globalController from "../../../mobx/GlobalController"
import { NoResults } from "../Logs"

export const periods = ["1 month", "6 month", "1 year", "All Time"]

const DotText = ({ color = "", text }) => (
	<div className="flex gap-[5px] items-center mb-2">
		<div
			className="w-2 h-2"
			style={{
				background: color,
				borderRadius: "50%",
			}}
		/>
		<div className="text-xs">{text}</div>
	</div>
)

const LegendTag = ({ children, green }) => (
	<div
		className="px-2 py-1 w-fit !font-medium !text-white !text-[13px]"
		style={{ borderRadius: 62, background: green ? "#74DC34" : "#0446F1" }}
	>
		{children}
	</div>
)

export const getIcon = ({ name, selected = false }) => {
	if (name === "PoliceCar") return <PoliceCar selected={selected} />
	if (name === "Syringe") return <Syringe selected={selected} />
	if (name === "Bank") return <Bank selected={selected} />
	if (name === "FirstAid") return <FirstAid selected={selected} />
	if (name === "Employerscore") return <Employerscore selected={selected} />
	if (name === "User") return <User selected={selected} />
	return null
}

export default function Stats() {
	const companyData = globalController.getData()
	const noData =
		companyData?.companyId &&
		!companyData?.lastReports?.length &&
		!companyData?.lastChangesRecent?.length

	// console.log('companyData', JSON.stringify(companyData));

	const [period, setPeriod] = React.useState(0)
	const [showAllReviews, setShowAllReviews] = useState(false)
	const [showAllRatingChanges, setShowAllRatingChanges] = useState(false)
	const [selected, setSelected] = useState(0)

	const name = selected ? iconsData[selected - 1].name : ""
	let backgroundColor = name ? "#2A5CDD" : "black"
	if (name?.includes("Credit")) backgroundColor = "#29B4EF"
	if (name?.includes("Drug")) backgroundColor = "#8E25E1"
	if (name?.includes("Med")) backgroundColor = "#17DA7C"
	if (name?.includes("Emp")) backgroundColor = "#FD8026"
	if (name?.includes("Peer")) backgroundColor = "#FD4A23"

	const currentPercent =
		selected > 0
			? iconsData[selected - 1].percent
			: (
					iconsData.reduce((acc, item) => acc + item.percent, 0) /
					iconsData.length
			  ).toFixed()

	return (
		<div className="flex flex-col">
			<PageTitle title="Statistics" showUser />
			<div className="flex gap-4">
				<PageBlock
					className="w-3/4 !items-start !justify-start statistics_onboarding_1"
					shadow
				>
					<div className="flex w-full">
						<div className="flex flex-col flex-1">
							<PageBlockTitle>Employee checks</PageBlockTitle>
							<div className="flex gap-3 items-center mt-4">
								<div className="flex gap-1 font-medium text-[13px]">
									{periods.map((el, i) => (
										<PeriodTag
											selected={period === i}
											onClick={() => setPeriod(i)}
											key={i}
										>
											{el}
										</PeriodTag>
									))}
								</div>
								<CalendarIcon />
							</div>
							{noData ? (
								<NoResults />
							) : (
								<>
									<div className="font-medium mt-6 mb-4 leading-none">
										<div className="text-[48px] text-[#0446F1]">5</div>
										<div className="text-[22px]">Added employees</div>
									</div>
									<div className="font-medium mb-6 leading-none">
										<div className="text-[48px] text-[#0446F1]">3</div>
										<div className="text-[22px]">Сompleted reports</div>
									</div>
									<div className="grid gap-2 grid-cols-2 grid-rows-2">
										<PageBlock smallBlue className="!items-start !px-4 !py-1">
											<div>
												<DotText color="#74DC34" text="Done" />
												<div className="flex gap-1 text-[#0446F1] items-end font-medium">
													<div className="text-[32px] leading-none">7%</div>
												</div>
											</div>
										</PageBlock>
										<PageBlock smallBlue className="!items-start !px-4 !py-1">
											<div>
												<DotText color="#FFCB14" text="In work" />
												<div className="flex gap-1 text-[#0446F1] items-end font-medium">
													<div className="text-[32px] leading-none">22%</div>
												</div>
											</div>
										</PageBlock>
										<PageBlock smallBlue className="!items-start !px-4 !py-1">
											<div>
												<DotText color="#ED2C2C" text="New" />
												<div className="flex gap-1 text-[#0446F1] items-end font-medium">
													<div className="text-[32px] leading-none">63%</div>
												</div>
											</div>
										</PageBlock>
										<PageBlock smallBlue className="!items-start !px-4 !py-1">
											<div>
												<DotText color="#000" text="Waiting confirmation" />
												<div className="flex gap-1 text-[#0446F1] items-end font-medium">
													<div className="text-[32px] leading-none">13%</div>
												</div>
											</div>
										</PageBlock>
									</div>
								</>
							)}
						</div>

						{!noData && (
							<div className="flex flex-1 flex-col items-center">
								<PageBlockTitle>Status by reports</PageBlockTitle>
								<div className="w-full aspect-square">
									<CirclesThin />
								</div>
							</div>
						)}
					</div>
				</PageBlock>
				<PageBlock
					noPadding
					className="w-1/4 !justify-start !items-start"
					shadow
				>
					<div className="px-6 pt-6 w-full">
						<PageBlockTitle className="mb-3">Last reports</PageBlockTitle>
						{!noData && (
							<>
								{mockData.reports
									.slice(0, showAllReviews ? mockData.reports.length : 4)
									.map((el, i) => (
										<div className="w-full" key={i + el.name}>
											<Divider />
											<div className="flex gap-2 items-center">
												<div
													className="bg-contain bg-no-repeat h-[30px] w-[29px] rounded"
													style={{ backgroundImage: `url(${el.src})` }}
												/>
												<div className="font-medium my-3">
													<div className="text-base">{el.name}</div>
													<div className="text-[13px]">{el.position}</div>
												</div>
											</div>
											<Button
												variant="smallBlue"
												className="bg-[#F6F7FC] h-fit mb-3"
												onClick={() => {
													globalController.setEmployee(el)
												}}
											>
												View
											</Button>
										</div>
									))}
							</>
						)}
					</div>
					{!showAllReviews && !noData ? (
						<>
							<Divider />
							<div
								className="font-medium text-[13px] text-[#0446F1] mx-auto my-4"
								role="button"
								tabIndex={0}
								onClick={() => setShowAllReviews((prev) => !prev)}
							>
								View all
							</div>
						</>
					) : (
						<NoResults />
					)}
				</PageBlock>
			</div>
			<div className="flex items-center gap-2 mt-9 mb-4">
				<div
					className="bg-contain bg-no-repeat h-[30px] w-[30px]"
					style={{ backgroundImage: "url(/images/Union.svg)" }}
				/>
				<PageBlockTitle className="statistics_onboarding_2">
					Key changes
				</PageBlockTitle>
			</div>
			<div className="flex gap-6">
				<PageBlock className="w-1/3 !justify-start !items-start" shadow>
					<PageBlockTitle>Employees</PageBlockTitle>
					{noData ? (
						<NoResults />
					) : (
						<>
							<div className="whitespace-pre-wrap font-medium text-[15px] mb-6">
								Changes in criminal record of Mr. Ivanov (arrested on August 20,
								2023, in relation to a robbery case) and have updated his rating
								80 à 20
							</div>
							<div className="w-full">
								<Divider />
								<div className="flex gap-2 my-3 w-full">
									<div
										className="bg-contain bg-no-repeat h-[30px] w-[29px] rounded"
										style={{
											backgroundImage: `url(${mockData.reports?.[0].src})`,
										}}
									/>
									<div className="font-medium">
										<div className="text-base">
											{mockData.reports?.[0].name}
										</div>
										<div className="text-[13px]">
											{mockData.reports?.[0].position}
										</div>
									</div>
									<Button
										variant="smallBlue"
										className="bg-[#F6F7FC] h-fit ml-auto"
										onClick={() => {
											globalController.setEmployee(mockData.reports?.[0])
										}}
									>
										View
									</Button>
								</div>
								<Points points={43} />
							</div>
						</>
					)}
				</PageBlock>
				<PageBlock className="w-1/3 !justify-start !items-start" shadow>
					<PageBlockTitle>Applicants</PageBlockTitle>
					{noData ? (
						<NoResults />
					) : (
						<>
							<div className="whitespace-pre-wrap font-medium text-[15px] mb-6">
								Changes in drug record of Mr. Petrov (arrested on June 20, 2023,
								in relation to a drug selling) and have updated his rating to 50
							</div>
							<Divider />
							<div className="flex gap-2 my-3 w-full">
								<div
									className="bg-contain bg-no-repeat h-[30px] w-[29px] rounded"
									style={{
										backgroundImage: `url(${mockData.reports?.[0].src})`,
									}}
								/>
								<div className="font-medium">
									<div className="text-base">{mockData.reports?.[0].name}</div>
									<div className="text-[13px]">
										{mockData.reports?.[0].position}
									</div>
								</div>
								<Button
									variant="smallBlue"
									className="bg-[#F6F7FC] h-fit ml-auto"
									onClick={() => {
										globalController.setEmployee(mockData.reports?.[0])
									}}
								>
									View
								</Button>
							</div>
							<Points points={43} />
						</>
					)}
				</PageBlock>
				<PageBlock className="w-1/3 !justify-start !items-start" shadow>
					<PageBlockTitle>Recent rating changes</PageBlockTitle>
					{noData ? (
						<NoResults />
					) : (
						<>
							{mockData.ratingChanges
								.slice(
									0,
									showAllRatingChanges ? mockData.ratingChanges.length : 2
								)
								.map((el, i) => (
									<div className="w-full mb-3" key={el.name + i}>
										<Divider />
										<div className="flex gap-2 my-3 w-full">
											<div
												className="bg-contain bg-no-repeat h-[30px] w-[29px] rounded"
												style={{ backgroundImage: `url(${el.src})` }}
											/>
											<div className="font-medium ">
												<div className="text-base">{el.name}</div>
												<div className="text-[13px]">{el.position}</div>
											</div>
											<Button
												variant="smallBlue"
												className="bg-[#F6F7FC] h-fit ml-auto"
												onClick={() => {
													globalController.setEmployee(el)
												}}
											>
												View
											</Button>
										</div>
										<div className="flex justify-between">
											<Points points={el.points} />
											<Points points={el.change} big />
										</div>
									</div>
								))}
							{!showAllRatingChanges && (
								<>
									<Divider />
									<div
										className="font-medium text-[13px] text-[#0446F1] mx-auto mt-4"
										role="button"
										tabIndex={0}
										onClick={() => setShowAllRatingChanges((prev) => !prev)}
									>
										View all
									</div>
								</>
							)}
						</>
					)}
				</PageBlock>
			</div>
			<div className="flex items-center gap-2 mt-9 mb-4">
				<div
					className="bg-contain bg-no-repeat h-[30px] w-[30px] bg-center"
					style={{
						backgroundImage: "url(/images/EmployeePerfomance.svg)",
						backgroundColor: "#05CD99",
						backgroundSize: "21px 25px",
						borderRadius: "50%",
					}}
				/>
				<PageBlockTitle>Employee perfomance</PageBlockTitle>
			</div>
			{noData ? (
				<PageBlock className="w-full !items-start mb-4" shadow>
					<NoResults />
				</PageBlock>
			) : (
				<PageBlock
					className="w-full !items-start mb-4 statistics_onboarding_3"
					shadow
				>
					<div className="flex gap-6 items-end mb-8">
						<Select label="Violations" />
						<Select label="Date" />
						<Select label="Candidats/Employees" />
						<Select label="HR" />
						<Button variant="mediumBlue" className="!py-1 !px-2 !h-[30px]">
							Search
						</Button>
					</div>
					<div className="w-full mb-[34px]">
						<EmployeePerfomanceGraph data={graphData} />
					</div>

					<div className="flex mt-8 mb-2 w-full">
						<div
							className="flex font-medium leading-none pr-4"
							style={{ width: "calc(50% - 8px)", color: "#0446F1" }}
						>
							<div className="flex flex-col mr-4">
								<div className="text-[40px]">5</div>
								<div className="text-[24px]">years</div>
								<RateChange percent={20} />
							</div>
							<div className="w-full h-full">
								<Chart
									data={chart5years}
									xGrid
									yGrid
									lineWidth={2}
									pointRadius={3}
								/>
							</div>
						</div>
						<div className="flex" style={{ width: "calc(50% - 8px)" }}>
							<div className="font-medium leading-none">
								<div className="flex flex-col mr-4">
									<div className="text-[40px]" style={{ color: "#0446F1" }}>
										500
									</div>
									<RateChange percent={20} />
								</div>
							</div>
							<div className="w-full h-full">
								<Chart
									data={chart500}
									xGrid
									yGrid
									lineWidth={2}
									pointRadius={3}
									barLabels={labelsDays}
									barDatasets={barDatasets}
									barOptions={barOptions}
								/>
							</div>
						</div>
					</div>

					<div className="flex w-full mb-8">
						<div className="w-1/2 flex gap-1 justify-center">
							<LegendTag>Average working time</LegendTag>
							<LegendTag green>% Succefful hires</LegendTag>
						</div>
						<div className="w-1/2 flex gap-1 justify-center">
							<LegendTag>Turnover</LegendTag>
							<LegendTag green>Employer number</LegendTag>
						</div>
					</div>

					<div className="flex w-full justify-between gap-[56px]">
						<div className="flex flex-col">
							<div
								className="font-medium text-[40px] leading-none w-[71px]"
								style={{ color: "#0446F1" }}
							>
								{currentPercent}
							</div>
							<RateChange percent={2.45} />
						</div>
						<div className="flex flex-1 flex-col">
							<div className="h-[130px]">
								<Chart
									data={
										!!selected
											? null
											: getMockDataTotal(labels[1], 1, {
													borderWidth: 2,
													borderColor: "#0446F1",
													fill: true,
													backgroundColor: "#0446F11A",
													pointRadius: 3,
													pointBackgroundColor: "#0446F1",
											  })
									}
									lineColor={backgroundColor}
									labels={labels[1]}
									tableData={mockDataChart[1].sort(() => Math.random() - 0.5)}
									xGrid
									yGrid
								/>
							</div>
							<Selector
								className="w-full mt-3 cursor-pointer font-medium"
								selected={selected}
							>
								<div
									style={{ color: selected === 0 ? "#fff" : "#8E8E8E" }}
									onClick={() => setSelected(0)}
								>
									Total
								</div>
								{iconsData.map((item, i) => (
									<div
										key={item.icon + i}
										style={{ color: selected === i + 1 ? "#fff" : "#8E8E8E" }}
										onClick={() => setSelected(i + 1)}
									>
										{/* <img src={`/images/score/${item.icon}.svg`} alt="" /> */}
										{getIcon({ name: item.icon, selected: selected === i + 1 })}
										{item.name}
									</div>
								))}
							</Selector>
						</div>
					</div>
				</PageBlock>
			)}
			<div className="flex gap-6">
				<PageBlock className="w-1/2 !items-start" shadow>
					<div className="flex items-center gap-2 mb-4">
						<div
							className="bg-contain bg-no-repeat h-[31px] w-[31px]"
							style={{ backgroundImage: "url(/images/KeyAspects.svg)" }}
						/>
						<PageBlockTitle>Key Aspects</PageBlockTitle>
					</div>
					{noData ? (
						<NoResults />
					) : (
						<div className="whitespace-pre-wrap font-medium text-[15px]">
							10% of employees have been identified in the federal criminal
							database for cases involving damages exceeding 100,000 USD. 50% of
							employees have experienced issues with substance abuse.
						</div>
					)}
				</PageBlock>
				<PageBlock className="w-1/2 !items-start" shadow>
					<div className="flex items-center gap-2 mb-4">
						<div
							className="bg-contain bg-no-repeat h-[31px] w-[31px]"
							style={{ backgroundImage: "url(/images/Trends.svg)" }}
						/>
						<PageBlockTitle>Trends</PageBlockTitle>
					</div>
					{noData ? (
						<NoResults />
					) : (
						<div className="whitespace-pre-wrap font-medium text-[15px]">
							There has been a 10% reduction in the number of employees
							implicated in criminal records
						</div>
					)}
				</PageBlock>
			</div>
			<PageBlock className="w-full mt-4 mb-6 !flex !flex-row !gap-6" shadow>
				<div className="flex flex-1">
					<div className="w-full">
						<div className="flex items-center gap-2">
							<div
								className="bg-contain bg-no-repeat h-[31px] w-[31px]"
								style={{
									backgroundImage: "url(/images/PerformanceReview.svg)",
								}}
							/>
							<PageBlockTitle>Performance review</PageBlockTitle>
						</div>
						{noData ? (
							<div className="flex">
								<NoResults />
							</div>
						) : (
							<div className="mt-6 flex flex-col mb-3">
								<div className="flex justify-between w-full font-medium">
									<div className="flex flex-1">Failed the survey</div>
									<div className="flex flex-1">Not verified</div>
									<div className="flex flex-1">Reviews are filled in</div>
								</div>

								<div className="w-full font-medium mt-2">
									<ColorLines data={mockData.performanceReview.data} />
								</div>
							</div>
						)}
					</div>
				</div>

				<div className="flex flex-1 flex-col gap-4">
					<div className="flex items-center gap-2 ml-6">
						<div
							className="bg-contain bg-no-repeat h-[31px] w-[31px]"
							style={{ backgroundImage: "url(/images/Trends.svg)" }}
						/>
						<PageBlockTitle>Trends</PageBlockTitle>
					</div>
					{noData ? (
						<NoResults />
					) : (
						<div className="whitespace-pre-wrap font-medium text-[15px] ml-6">
							1% of employees did not complete the self-assessment
							questionnaire. 5% of reviews are unverified.
						</div>
					)}
				</div>
			</PageBlock>

			<div className="flex items-center gap-2 ml-6 mb-4">
				<div
					className="bg-contain bg-no-repeat h-[31px] w-[31px]"
					style={{ backgroundImage: "url(/images/EmployeeSkills.svg)" }}
				/>
				<PageBlockTitle>Employee skills</PageBlockTitle>
			</div>

			{noData ? (
				<PageBlock>
					<NoResults />
				</PageBlock>
			) : (
				<PageBlock className="w-full !items-start">
					<div className="flex w-full">
						<div className="w-2/3">
							<EmployeeSkillsGraph />
						</div>
						<div className="w-1/3">
							<div className="flex items-center gap-2">
								<div
									className="bg-contain bg-no-repeat h-[31px] w-[31px]"
									style={{ backgroundImage: "url(/images/KeyIssues.svg)" }}
								/>
								<PageBlockTitle>Key issues</PageBlockTitle>
							</div>
							<div className="w-3/4 py-[30px] font-medium text-[15px]">
								80% possess high communication skills.
								<br />
								20% have been noted for petty theft.
							</div>
							<div className="flex items-center gap-2">
								<div
									className="bg-contain bg-no-repeat h-[31px] w-[31px]"
									style={{ backgroundImage: "url(/images/Trends.svg)" }}
								/>
								<PageBlockTitle>Trends</PageBlockTitle>
							</div>
							<div className="w-3/4 pt-6 font-medium text-[15px]">
								Over the past year, there has been a 20% reduction in the number
								of employees involved in petty theft.
							</div>
						</div>
					</div>
				</PageBlock>
			)}
			<div className="flex items-center gap-2 mt-8 mb-4">
				<div
					className="bg-contain bg-no-repeat h-[31px] w-[31px]"
					style={{ backgroundImage: "url(/images/EmployeeSatsfaction.svg)" }}
				/>
				<PageBlockTitle>Employee satisfaction</PageBlockTitle>
			</div>

			{noData ? (
				<PageBlock>
					<NoResults />
				</PageBlock>
			) : (
				<PageBlock className="!items-start">
					<div className="flex w-full mb-[62px]">
						<div className="w-1/3">
							<Circles />
						</div>
						<div className="w-2/3">
							<Chart
								data={chartES}
								// barDatasets={chartESBars}
								// barLabels={labelsDays}
								// barOptions={barOptions}
								lineWidth={2}
								xGrid
								yGrid
								xBorder
								yBorder
								pointRadius={chartES.datasets[0].data.map((el, i, arr) => {
									const newArr = [...arr].sort((a, b) => b - a)
									const [max1, max2] = newArr.slice(0, 2)
									if (el === max1 || el === max2) return 3
									return 1
								})}
							/>
						</div>
					</div>
					{mockData.EmployeeSatisfaction.map((item) => (
						<div
							key={item.name}
							className="w-full font-medium flex flex-col gap-[6px] mb-[28px] last:mb-0"
						>
							<div className="text-base">{item.name}</div>
							<ColorLines data={item.data} />
						</div>
					))}
					<div className="flex gap-[5px] font-medium">
						{mockData.legends.map((item, i) => (
							<div
								key={i}
								className="px-2 py-1 flex justify-center items-center rounded-[62px] text-white w-20"
								style={{ backgroundColor: colors[i] }}
							>
								{item}
							</div>
						))}
					</div>
				</PageBlock>
			)}
		</div>
	)
}
