import './App.css';
import './styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';

import { App } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Layout from './components/Layout';
import Menu from './components/Menu';
import { routes } from './AppRoutes';
import { observer } from 'mobx-react-lite';
import globalController from './mobx/GlobalController';
import Page404 from './modules/Page404/Page404';
import Login from './modules/Login';
import Register from './modules/Register/Company';
import Self from './modules/Company/Self';
import * as statistic from './utils/httpServices/statistic';
import { getTariffs } from './utils/httpServices/tariffs';
import OnboardingWrapper from './components/OnboardingWrapper/OnboardingWrapper';

export type Account = 'company' | 'user' | '';

const MyApp = observer(() => {
  const [device, setDevice] = useState('desktop');
  const account = globalController.getAccount() as Account;
  const employee = globalController.getEmployee();
  const token = globalController.getToken();
  const accData = globalController.getData();

  const steps = [
    {
      target: '.my-first-step',
      content: 'This is my awesome feature!',
    },
    {
      target: '.my-other-step',
      content: 'This another awesome feature!',
    },
  ];

  const loadData = useCallback(async () => {
    if (!token) return;
    try {
      const companyId = accData?.id;
      let lastChangesRecentResponse, lastReportsResponse, employerHRResponse;
      if (companyId) {
        lastChangesRecentResponse = await statistic.loadLastChangesRecent(companyId, 10, 1);
        lastReportsResponse = await statistic.loadLastReports(companyId, 10, 1);
        employerHRResponse = await statistic.loadEmployerHRData(companyId, 10, 1);
      }

      const tariffsResponse = await getTariffs();
      const fullStatisticResponse = await statistic.loadFullStatistic(10, 1);
      const employerPerformanceResponse = await statistic.loadEmployerPerformanceData();

      const data = globalController.getData() || {};

      // Save all the data to globalController
      globalController.setData({
        ...data,
        tariffs: tariffsResponse,
        lastChangesRecent: lastChangesRecentResponse,
        lastReports: lastReportsResponse,
        fullStatistic: fullStatisticResponse,
        employerHR: employerHRResponse,
        employerPerformance: employerPerformanceResponse,
      });
    } catch (error) {
      console.error(error);
    }
  }, [accData, token]);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      if (innerWidth <= 576) setDevice('mobile');
      if (innerWidth > 576 && innerWidth < 1280) setDevice('tablet');
      if (innerWidth >= 1280) setDevice('desktop');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (account === 'company') {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <App>
      <BrowserRouter>
        <Layout device={device}>
          <Menu isMobile={device === 'mobile'} />
          <div className="flex flex-col p-6 flex-1" style={{ boxSizing: 'border-box' }}>
            <Routes>
              <Route path="/register" element={<Register />} />
              <Route path="/register-user" element={<Register user />} />
              <Route path="/auth" element={<Register />} />
              <Route path="/auth-user" element={<Register user />} />
              <Route path="/self" element={<Self />} />
              {!account ? (
                <>
                  <Route path="/" element={<Login />} />
                </>
              ) : (
                routes[account].map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))
                
              )}
              {account === 'company' &&
                employee &&
                routes.user
                  .slice(0, 4)
                  .map(({ path, element }) => <Route key={path} path={path} element={element} />)}
              <Route path="/*" element={<Page404 />} />
            </Routes>
          </div>
        </Layout>
        <OnboardingWrapper />
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </App>
  );
});

export default MyApp;
