import { makeAutoObservable } from 'mobx';

import { toast } from 'react-toastify';

import { Account } from '../App';


const modals = {
  addEmployee: false,
  self: false,
};

interface IModals {
  name: keyof typeof modals;
  value: boolean;
}

interface Employee {
  name: string;
  position: string;
  src: string;
  points?: number;
  change?: number;
}

export interface GlobalOnboardingState {
  globalStatus: boolean
  toursState: OnboardingState[];
}

export interface OnboardingState {
  name: string;
  run: boolean;
  stepIndex: number;
  steps: any[];
  tourLength: number;
  tourActive: boolean;
}

class GlobalController {
  requestCount = 0;

  account = '';

  employee: Employee | null = null;

  companyData: any | null = null;

  userData: any | null = null;

  token = '';

  modals = {
    addEmployee: false,
    self: false,
  };


  get waitForAnswer() {
    return this.requestCount > 0;
  }

  constructor() {
    makeAutoObservable(this);
  }

  startRequest = () => {
    this.requestCount += 1;
  };

  stopRequest = () => {
    if (this.requestCount > 0) {
      this.requestCount -= 1;
    }
  };

  setAccount = (account: Account) => {
    this.account = account;
    return this.account;
  };

  // setCompany = (data: any) => {
  //   this.company = data;
  //   return this.company;
  // };

  // getCompany = () => this.company;

  setToken = (token: string) => {
    this.token = token;
    return this.token;
  };

  getToken = () => this.token;

  getModals = () => this.modals;

  setModal = ({ name, value }: IModals) => {
    if (this.modals[name] !== undefined) {
      this.modals[name] = value;
    }
    return this.modals;
  };

  getAccount = () => this.account;

  getEmployee = () => this.employee;

  setEmployee = (
    employee: {
      name: string;
      position: string;
      src: string;
      points?: number;
      change?: number;
    } | null,
  ) => {
    this.employee = employee;
    return this.employee;
  };

  getData = () => this.companyData;

  setData = (data: any) => {
    this.companyData = data;
    return this.companyData;
  };

  getUserData = () => this.userData;

  setUserData = (data: any) => {
    this.userData = data;
    return this.userData;
  };

  handleError({ error, message = 'Server Error' }: { error: any; message?: React.ReactNode }) {
    this.stopRequest();
    console.error('handleError', error, message);
    toast(typeof error === 'string' ? error : message, { type: 'error' });
    console.error(error || message);
  }

  // Onboarding tours

  getGloabalOnboaridngState = (): GlobalOnboardingState | null => {
    const storedStateString = localStorage.getItem('onboardingTourState')

    if(!storedStateString) {
      return null
    }
    
    const state:GlobalOnboardingState = JSON.parse(storedStateString)
    return state
  }


}

const globalController = new GlobalController();
export default globalController;
