import React, { useState } from 'react';
import Button from '../../../components/Button';
import { SelectDiv } from '../../../components/Select/Select';
import css from './index.module.css';
import clsx from 'clsx';
import { USStates } from '../../../utils/states';

interface IProps {
  values: {
    data: any;
    setData: any;
    handleOk: any;
    countries: any[];
  };
}

export const RegisterCompany = ({ values }: IProps) => {
  const { data, setData, handleOk, countries } = values;
  const [isVisibleAddress2, setIsVisibleAddress2] = useState(false);

  return (
    <>
      <input
        placeholder="EIN"
        className={clsx(css.input, css.onbouarding_ein)}
        value={data.ein || ''}
        onChange={(e) => setData((prev: any) => ({ ...prev, ein: e.target.value }))}
      />
      <input
        placeholder="Company Name"
        className={clsx(css.input, css.onbouarding_company_name)}
        value={data.companyName || ''}
        onChange={(e) => setData((prev: any) => ({ ...prev, companyName: e.target.value }))}
      />
      <input
        placeholder="Company Phone"
        className={css.input}
        value={data.companyPhone || ''}
        onChange={(e) => setData((prev: any) => ({ ...prev, companyPhone: e.target.value }))}
      />
      <input
        placeholder="URL"
        className={css.input}
        value={data.url || ''}
        onChange={(e) => setData((prev: any) => ({ ...prev, url: e.target.value }))}
      />
      <input
        placeholder="Email"
        className={css.input}
        value={data.email || ''}
        onChange={(e) => setData((prev: any) => ({ ...prev, email: e.target.value }))}
      />
      <input
        placeholder="About"
        className={css.input}
        value={data.about || ''}
        onChange={(e) => setData((prev: any) => ({ ...prev, about: e.target.value }))}
      />
      <SelectDiv
        placeholder="Country"
        containerClassName="w-full "
        className={clsx(css.input, '!bg-white')}
        value={data.country || ''}
        options={countries}
        label=""
        color={undefined}
        disabled={false}
        onChange={(country: any) => setData((prev: any) => ({ ...prev, country }))}
        customOpenBtn={undefined}
      />
      {data.country === 'United States' && (
        <SelectDiv
          placeholder="State"
          containerClassName="w-full "
          className={clsx(css.input, '!bg-white')}
          value={data.state || ''}
          options={USStates}
          label=""
          color={undefined}
          disabled={false}
          onChange={(state: any) => setData((prev: any) => ({ ...prev, state }))}
          customOpenBtn={undefined}
        />
      )}
      <input
        placeholder="ZIP Code"
        className={css.input}
        value={data.code || ''}
        onChange={(e) => setData((prev: any) => ({ ...prev, code: e.target.value }))}
      />

      <div className="relative w-full">
        <input
          placeholder="Address"
          className={css.input}
          value={data.address || ''}
          onChange={(e) => setData((prev: any) => ({ ...prev, address: e.target.value }))}
        />
        <button
          className="absolute right-1 border flex items-center justify-center top-1/2 cursor-pointer w-6 h-6"
          onClick={() => setIsVisibleAddress2((prev) => !prev)}
          style={{
            transform: 'translateY(-50%)',
            fontSize: '20px',
            borderRadius: '50%',
            color: 'grey',
          }}
        >
          {isVisibleAddress2 ? '-' : '+'}
        </button>
      </div>
      {isVisibleAddress2 && (
        <input
          placeholder="Address 2"
          className={css.input}
          value={data.otherCompanyAddress || ''}
          onChange={(e) =>
            setData((prev: any) => ({ ...prev, otherCompanyAddress: e.target.value }))
          }
        />
      )}
      <Button
        variant="bigBlue"
        onClick={handleOk}
        className={css.sendBtn}
        disabled={
          !data.ein ||
          !data.companyName ||
          !data.companyPhone ||
          !data.url ||
          !data.email ||
          !data.about ||
          !data.country ||
          !data.code ||
          !data.address
        }
      >
        Send
      </Button>
    </>
  );
};
