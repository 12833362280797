import axiosInstance from '.';
import {URL_API_DEV} from "../consts";
import clsx from "clsx";
import css from "../../modules/Login/index.module.css";
import Button from '../../components/Button';

// Функция для логина кастомера
export const loginUser = async (data) => {
  try {
    const response = await axiosInstance.post('/api/customers/auth', data);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    console.log(error);
    return { error: error.response.data };
  }
};

// Функция для регистрации кастомера
export const registerUser = async (data) => {
  try {
    const response = await axiosInstance.post('/api/customers', data);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.message };
  }
};

// Функция для обновления кастомера
export const updateUser = async (id, data) => {
  try {
    const response = await axiosInstance.put(`/api/customers/${id}`, data);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для добавления информации по верификации кастомера
export const addVerificationInfo = async (data) => {
  try {
    const response = await axiosInstance.post('/api/customers/verification', data);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для обновления информации по кастомеру (scores, names, languages, skills, universities, jobs, courses, about)
export const updateCustomerInfo = async (data) => {
  try {
    const response = await axiosInstance.post(`/api/customers/update_customer_info`, data);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для получения информации по университету
export const getCustomerUniversity = async (pageSize, page, customer_id) => {
  try {
    const response = await axiosInstance.post(
      `/api/customers/university_user/${pageSize}/${page}/${customer_id}`,
    );
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для получения информации по скиллам кастомера
export const getCustomerSkills = async (pageSize, page, customer_id) => {
  try {
    const response = await axiosInstance.post(
      `/api/customers/skills/${pageSize}/${page}/${customer_id}`,
    );
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для получения информации по языкам кастомера
export const getCustomerLanguages = async (pageSize, page, customer_id) => {
  try {
    const response = await axiosInstance.post(
      `/api/customers/language/${pageSize}/${page}/${customer_id}`,
    );
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для получения информации по работам кастомера
export const getCustomerJobs = async (pageSize, page, customer_id) => {
  try {
    const response = await axiosInstance.post(
      `/api/customers/jobs_user/${pageSize}/${page}/${customer_id}`,
    );
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для получения информации по кастомеру (ssn, scores, names, languages, skills, universities, jobs, courses, about)
export const getCustomerInfo = async (pageSize, page, customer_id) => {
  try {
    const response = await axiosInstance.post(
      `/api/customers/info_customer/${pageSize}/${page}/${customer_id}`,
    );
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для получения кастомера по емайлу и SSN
export const getCustomerBySSNandEmail = async (pageSize, page, ssn, email) => {
  try {
    const response = await axiosInstance.post(
      `/api/customers/find/${pageSize}/${page}/${ssn}/${email}`,
    );
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для получения информации по курсам кастомера
export const getCustomerCourses = async (pageSize, page, customer_id) => {
  try {
    const response = await axiosInstance.post(
      `/api/customers/course/${pageSize}/${page}/${customer_id}`,
    );
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для добавления информации по кастомеру (ssn, scores, names, languages, skills, universities, jobs, courses, about)
export const addCustomerInfo = async (data) => {
  try {
    const response = await axiosInstance.post('/api/customers/add_customer_info', data);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для удаления кастомера
export const deleteCustomer = async (id) => {
  try {
    const response = await axiosInstance.delete(`/api/customers/${id}`);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};


export const oktaAuth = async () => {
  //return await axiosInstance.get('/success');
  return await axiosInstance.get('/okta');

};


const LinkOkta = () => {
  const backendBaseUrl = URL_API_DEV;

  const goAuth = () => {
    const popupWindow = window.open(`${backendBaseUrl}/oauth2/authorization/okta`, '_top');
  };

  return (
      <div>
        <Button variant="bigBlue"
                className={clsx('w-[10rem]', css.button)} onClick={goAuth}>OAUTH0</Button>
      </div>
  );
};

export default LinkOkta
