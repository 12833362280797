import React, { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { getIcon } from '../Icons/MenuIcons';
import globalController from '../../mobx/GlobalController';
import css from './index.module.css';
import { menuItemsUser, menuItemsCompany, newItemz } from './menuItems';
import Divider from '../Divider';
import AddEmployee from '../Modals/AddEmployee';

interface IProps {
  isMobile?: boolean;
}

const Menu = observer(({ isMobile }: IProps) => {
  const account = globalController.getAccount();
  const employee = globalController.getEmployee();
  const isModalOpenAddEmployee = globalController.getModals().addEmployee;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const companyID = query.get('companyID');
  const employeeID = query.get('employeeID');

  const isEmptyCompany = !account && companyID && employeeID;

  const items = useMemo(() => {
    let newItems = account === 'company' ? menuItemsCompany : menuItemsUser;
    if (!account) newItems = [];
    if (account === 'company' && employee && !newItems.includes(menuItemsUser[0])) {
      newItems.splice(2, 0, ...menuItemsUser.slice(0, 4), ...newItemz);
    }
    return newItems;
  }, [account, employee]);

  const logout = () => {
    globalController.setAccount('');
    globalController.setToken('');
    globalController.setData(null);
    navigate('/');
  };

  if (pathname.includes('register') || pathname.includes('auth') || isEmptyCompany) return null;

  return (
    <>
      <nav className={css.wrapper}>
        <img src="/images/logo.svg" alt="EPass logo" className="mb-[22px]" />
        {items.map(({ name, url, icon }, i) => (
          <React.Fragment key={name}>
            {account === 'company' && employee && (i === 2 || i === 7) && <Divider />}
            <button
              onClick={() => {
                i < items.length - 1 ? navigate(url) : logout();
              }}
              className={clsx(css.menuItem, pathname === url && css.selected, `onboarding_menu_btn_index_${i}`)}
            >
              {getIcon({ name: icon, fill: pathname === url ? '#fff' : '#989FAD' })}
              <span>{name}</span>
            </button>
          </React.Fragment>
        ))}
      </nav>
      {isModalOpenAddEmployee && (
        <AddEmployee
          visible={isModalOpenAddEmployee}
          onCancel={() => {
            globalController.setModal({ name: 'addEmployee', value: false });
          }}
          // onDelete={handleDeleteGen}
          // onChange={handleChangeGen}
        />
      )}
    </>
  );
});

export default Menu;
