// export const URL_API_DEV = 'http://64.227.106.88:8081';
// export const URL_API_PROD = 'http://64.227.106.88:8080';

export const URL_API_PROD = 'https://epass-prod.es.dc63.ru';
export const URL_API_DEV = 'https://epass-dev.es.dc63.ru';
export const URL_API = URL_API_DEV;

export const shuftiPro = {
  "clientId": "797b202a667a5404fa5085e39395f15aa8d6391fa97640a05f3ca3e4ae823780",
  "secretKey": "t4avxxotbK1hGtbJfkacADEMCzH3EogA",
  "statusEndpoint": "https://api.shuftipro.com/status",
  "requestEnpoint": "https://api.shuftipro.com/",
  "origin": "https://app.shuftipro.com"
}

export const COLORS = {
  black: '#000',
  red: '#ED2C2C',
  yellow: '#FFCB14',
  green: '#74DC34',
  white: '#fff',
  blue: '#0446F1',
};
