import React, { useCallback, useState } from "react"
import PageTitle from "../../../components/PageTitle"
import PageBlock, { PageBlockTitle } from "../../../components/PageBlock"
import CalendarIcon from "../../../components/Icons/CalendarIcon"
import PeriodTag from "../../User/MyScores/PeriodTag"
import { periods } from "../Stats"
import { DatePicker } from "antd"
import { InputDiv } from "../../../components/Select/Select"
import Button from "../../../components/Button"
import Divider from "../../../components/Divider"
import Points from "../Stats/Points"
import { mockResults } from "./mockData"
import { Dots } from "../../../components/Icons/Dots"
import css from "./index.module.css"
import globalController from "../../../mobx/GlobalController"
import { NoResults } from "../Logs"

const initFilters = {
	name: "",
}

export default function EmpSearch() {
	// console.log(JSON.stringify(companyData, null, 2));
	const companyData = globalController.getData()
	const noData =
		companyData?.companyId &&
		!companyData?.lastReports?.length &&
		!companyData?.lastChangesRecent?.length

	const [period, setPeriod] = useState(0)
	const [optinsIdx, setOptionsIdx] = useState(-1)
	const [showAll, setShowAll] = useState(false)
	const [filters, setFilters] = React.useState(initFilters)
	const [data, setData] = useState(mockResults)

	const handleFilters = useCallback(() => {
		const newData = [...mockResults].filter((log) =>
			filters.name
				? log.name.toLowerCase().includes(filters.name.toLowerCase())
				: true
		)
		setData(newData)
	}, [filters])

	return (
		<div className="flex flex-col">
			<PageTitle title="Employee search" showUser />
			<div className="flex flex-col gap-4">
				<PageBlock className="!items-start !justify-start">
					<div className="flex gap-5 w-full">
						<PageBlockTitle>Date added</PageBlockTitle>
						<div className="flex gap-3 items-center">
							<div className="flex gap-1 font-medium text-[13px]">
								{periods.map((el, i) => (
									<PeriodTag
										selected={period === i}
										onClick={() => setPeriod(i)}
										key={i}
									>
										{el}
									</PeriodTag>
								))}
							</div>
							<CalendarIcon />
						</div>
						<div className="ml-auto w-fit">
							<Button
								variant="bigBlue"
								className="!px-4 !py-[10px] !font-semibold !rounded-[25px] onboarding_employee_btn"
								onClick={() => {
									globalController.setModal({
										name: "addEmployee",
										value: true,
									})
								}}
							>
								Add employee
							</Button>
						</div>
					</div>
					<div className="font-medium text-[#A3A3A3] mt-5 mb-1">Date</div>
					<DatePicker.RangePicker />
				</PageBlock>
				<PageBlock className="!items-start !justify-start">
					<PageBlockTitle>Search by standards</PageBlockTitle>
					<div className="flex gap-3 items-end my-8">
						<InputDiv
							label="Name"
							color="#A3A3A3"
							value={filters.name}
							onChange={(e) =>
								setFilters((prev) => ({ ...prev, name: e.target.value }))
							}
							className="!min-w-[220px]"
						/>
						<InputDiv
							label="SSN"
							color="#A3A3A3"
							value={filters.ssn}
							onChange={(e) =>
								setFilters((prev) => ({ ...prev, ssn: e.target.value }))
							}
							className="!min-w-[220px]"
						/>

						<Button
							variant="mediumBlue"
							className="!py-1 !px-2 !h-[30px]"
							onClick={handleFilters}
						>
							Search
						</Button>
					</div>
				</PageBlock>
				<PageBlock className="!items-start !justify-start">
					<PageBlockTitle>Results</PageBlockTitle>

					{noData ? (
						<NoResults />
					) : (
						<>
							{data.slice(0, showAll ? data.length : 3).map((el, i) => (
								<div className="w-full mt-3" key={el.name + el.position + i}>
									<Divider />
									<div className="flex gap-2 mt-3 w-full">
										<div
											className="bg-contain bg-no-repeat h-[30px] w-[29px] rounded"
											style={{ backgroundImage: `url(${el.src})` }}
										/>
										<div className="font-medium">
											<div className="text-base">{el.name}</div>
											<div className="text-[13px]">{el.position}</div>
										</div>
										<div className="flex flex-col items-end gap-3 ml-auto relative">
											<Button
												variant="smallBlue"
												className="bg-[#F6F7FC] h-fit onboarding_view_employee_example"
												onClick={() => {
													globalController.setEmployee(el)
												}}
											>
												View
											</Button>
											<Dots
												className={css.moreOptions}
												onClick={() =>
													setOptionsIdx((prev) => (prev !== i ? i : -1))
												}
												onBlur={() => setOptionsIdx(-1)}
											/>
											<div
												className="font-medium text-[13px] text-[#8E8E8E]"
												style={{ display: i === optinsIdx ? "flex" : "none" }}
												role="button"
												tabIndex={0}
												onClick={() => setOptionsIdx(-1)}
											>
												<div>Send for verification</div>
												<div>Doesn’t work</div>
												<div>Leave a review</div>
											</div>
										</div>
									</div>
									<Points points={el.points} />
								</div>
							))}
							{!showAll && (
								<>
									<Divider className="mt-3" />
									<div
										className="font-medium text-[13px] text-[#0446F1] mx-auto mt-4"
										role="button"
										tabIndex={0}
										onClick={() => setShowAll((prev) => !prev)}
									>
										View all
									</div>
								</>
							)}
						</>
					)}
				</PageBlock>
			</div>
		</div>
	)
}
