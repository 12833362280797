import loginScreenCss from "../modules/Login/index.module.css"
import registerScreenCss from "../modules/Register/Company/index.module.css"
import { Outlet, useNavigate } from 'react-router-dom';
import { Step, Placement } from 'react-joyride';

export const steps: Step[] = [
	{//0
		target: `body`, 
		content: "Welcome to your company's personal office! Let's embark on an interactive tour to get you started!",
		placement: "center" as Placement,
	},
	{//1
		target: `.${loginScreenCss.buttonRegisterCompany}`,
		content: "First, let's register your company on our website. Click the 'Register as Company' button to begin the process.",
		placement: "top" as Placement,
		disableBeacon: true,
		spotlightClicks: true,
		hideFooter: true,
		hideCloseButton: true,
		disableOverlay: true
	},
	{//2
		target: `.${registerScreenCss.onbouarding_ein}`,
		content: "To register your company, it's crucial to fill in all the required fields. Start with your EIN and proceed to fill out all necessary details. Ensuring all fields are completed accurately is essential for moving on to the next step. ",
		placement: "top" as Placement,
		disableBeacon: true,
		spotlightClicks: true,
		disableOverlay: true
		
	},
    {//3
		target: `.${registerScreenCss.sendBtn}`,
		content: "Ensure all fields are completed, then click the 'Register' button to access your personal account.",
		placement: "bottom-end" as Placement,
		disableBeacon: true,
		spotlightClicks: true,
		hideFooter: true,
		disableOverlay: true,
		hideCloseButton: true,
	},
	{//4
		target: `body`,
		content: "Welcome to your personal account. Let's explore the main features and add an employee",
		placement: "center" as Placement,
		hideBackButton: true,
	},
	{//5
		target: `.onboarding_menu_btn_index_1`,
		content: "Now, let's proceed to adding an employee, which is the first step in conducting a background screening. Navigate to the 'Employees' page to get started.",
		placement: "right" as Placement,
		disableBeacon: true,
		data: {
            next: '/search',
          },
		spotlightClicks: true,
		hideFooter: true,
		disableOverlay: true

		// hideFooter: true,
	},
	{//6
		target: `.onboarding_employee_btn`,
		content: "Click the 'Add Employee' button to begin adding new team members.",
		placement: "left-start" as Placement,
		disableBeacon: true,
		// disableOverlayClose: true,
        // hideCloseButton: true,
        hideFooter: true,
		spotlightClicks: true,
		disableOverlay: true
	},
	{//7
		target: `.onboarding_view_employee_example`,
		content: "And now, click the 'View' button to see the detailed information about the employee",
		placement: "top" as Placement,
		disableBeacon: true,
		spotlightClicks: true,
		hideFooter: true,
		hideCloseButton: true,
		disableOverlayClose: true,
		// disableOverlay: true
	},
	{//8
		target: `.onboarding_menu_btn_index_2`,
		content: "This button will show you detailed employee results",
		placement: "right" as Placement,
		disableBeacon: true,
		data: {
            next: '/profile',
          },
		spotlightClicks: true,
		hideFooter: true,
		disableOverlay: true
	},
	{//9	
		target: `.onboarding_profile_total_score`,
		content: "This step showcases the overall scoring. With this feature, you no longer need to sift through dozens of report pages. Simply glance at the final score we've compiled for you. Unlike our competitors, who provide reports spanning countless pages, we offer a streamlined solution: if you're short on time, just check the final score",
		placement: "bottom-end" as Placement,
		disableBeacon: true,
		disableOverlayClose: true,
		disableOverlay: true
	},
	{//10
		target: `.onboarding_profile_score_board`,
		content: "If you have the time and interest in detailed analysis, we've broken down the scoring into specific components. Here, you can see which parameters your current or potential employee excels in and where there's room for improvement. This detailed view allows you to make well-informed decisions based on comprehensive insights",
		placement: "bottom-end" as Placement,
		disableBeacon: true,
		disableOverlayClose: true,
		disableOverlay: true
	},
	{//11
		target: `.onboarding_menu_btn_index_3`,
		content: "If you need even deeper insights, click the 'My Scores' button to view the complete history of changes. This will provide you with a comprehensive look at the employee's performance over time, allowing for a thorough understanding of their development and progress.",
		placement: "right" as Placement,
		disableBeacon: true,
		data: {
            next: '/scores',
          },
		spotlightClicks: true,
		hideFooter: true,
		disableOverlay: true
	},
	// {//12 ВЫПИЛИТЬ
	// 	target: `body`,
	// 	content: "NEW. This page telling about scores in more details",
	// 	placement: "center" as Placement,
	// 	data: {
    //         previous: '/scores',
    //       },
	// },
	{//12
		target: `.onboarding_menu_btn_index_4`,
		content: "To learn more about education, click the 'Education' button. Here, you will find information on educational backgrounds, courses, and skills. This section provides comprehensive details to help you understand the qualifications and training of your employees",
		placement: "right" as Placement,
		disableBeacon: true,
		data: {
            next: '/education',
			previous: '/profile'
          },
		spotlightClicks: true,
		hideFooter: true,
		disableOverlay: true
	},
	// {//14 ВЫПИЛИТЬ
	// 	target: `body`,
	// 	content: "NEW. This page telling about education programms",
	// 	placement: "center" as Placement,
	// 	data: {
    //         previous: '/scores',
    //       },
	// 	disableOverlay: true
	// },
	{//13
		target: `.onboarding_menu_btn_index_6`,
		content: "And now, let's proceed to the employee's self-assessment. Click the 'Verification' button to get started",
		placement: "right" as Placement,
		disableBeacon: true,
		data: {
            next: '/self',
			previous: '/scores'
          },
		spotlightClicks: true,
		hideFooter: true,
		disableOverlay: true
	}, 
	// {//16 ВЫПИЛИТЬ!
	// 	target: `body`,
	// 	content: "NEW. Verifications page",
	// 	placement: "center" as Placement,
	// 	data: {
    //         previous: '/education',
    //       },
	// 	disableOverlay: true
	// },
	{//14
		target: `.onboarding_profile_verification_step1`,
		content: "To verify your employee's self-assessment, click this button. This step ensures that all the provided information is accurate and trustworthy",
		placement: "right-end" as Placement,
		disableBeacon: true,
		disableOverlayClose: true,
		spotlightClicks: true,
		hideFooter: true,
		disableOverlay: true
	},
	{//15
		target: `.onboarding_menu_btn_index_0`,
		content: "After reviewing the individual employee report, you can view the overall information about your company. Click the 'Statistics' button to access this comprehensive overview.",
		placement: "right" as Placement,
		disableBeacon: true,
		data: {
            next: '/stats',
			
          },
		spotlightClicks: true,
		hideFooter: true,
		disableOverlay: true
	},
	{//16
		target: `.statistics_onboarding_1`,
		content: "In this widget, you can view the overall statistics for the company.",
		placement: "right-end" as Placement,
		disableBeacon: true,
		data: {
			previous: '/search'
          },
		disableOverlayClose: true,
		disableOverlay: true
	},
	{//17
		target: `.statistics_onboarding_2`,
		content: "In this section, you can view key changes over recent times.",
		placement: "bottom-end" as Placement,
		disableBeacon: true,
		disableOverlay: true
	},
	{//18
		target: `.statistics_onboarding_3`,
		content: "Employee performance is a key factor for the company's operation. Don't forget to check this section regularly.",
		placement: "right-end" as Placement,
		disableBeacon: true,
		disableOverlay: true
	},
	{//19
		target: `.onboarding_menu_btn_index_9`,
		content: "Next, let's explore the FAQ section. Click the 'FAQ' button to find answers to common questions and gain additional insights into our features and services",
		placement: "right" as Placement,
		disableBeacon: true,
		data: {
            next: '/help',
          },
		spotlightClicks: true,
		hideFooter: true,
		disableOverlay: true
	},
	{//20
		target: `body`,
		content: "Here you can find answers to all your questions and even a little bit more.",
		placement: "center" as Placement,
		data: {
            previous: '/stats',
          },
		disableOverlay: true
	},
	{//21
		target: `.onboarding_help_item_2`,
		content: "Click here to see Company contacts",
		placement: "right-end" as Placement,
		disableBeacon: true,
		spotlightClicks: true,
		disableOverlay: true
	},
	{//22
		target: `.onboarding_menu_btn_index_7`,
		content: "Great, now let's familiarize ourselves with the pricing plans.",
		placement: "right" as Placement,
		disableBeacon: true,
		data: {
            next: '/payments',
          },
		spotlightClicks: true,
		hideFooter: true,
		disableOverlay: true
	},
	{//23
		target: `body`,
		content: "On this page, you'll discover our tailored pricing plans designed to meet your specific needs. Explore the various options and find the perfect plan.",
		placement: "center" as Placement,
		data: {
            previous: '/help',
          },
		disableOverlay: true
	},
	{//24
		target: `body`,
		content: "That's it! Our journey has come to an end. You can always take the tour again by going to settings and clicking on the 'Take Tour' button.",
		placement: "center" as Placement,
	}
]