import Joyride, {
	ACTIONS,
	EVENTS,
	ORIGIN,
	STATUS,
	CallBackProps
} from "react-joyride"
import { OnboardingTour } from "../OnboardingWrapper/OnboardingWrapper";
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';

interface OnboardingProps {
	run: boolean;
	stepIndex: number;
	steps: any[];
	setOnboardingState: Dispatch<SetStateAction<OnboardingTour | undefined>>;
}

export default function Onboarding({steps, run, stepIndex, setOnboardingState}: OnboardingProps) {
	const navigate = useNavigate();

	// JoyrideHandler
	const handleClick = (data:CallBackProps) => {
		const {type, step, action, index, lifecycle, status} = data

		console.log(data)

		if(action === ACTIONS.NEXT && status === STATUS.FINISHED) {
			setOnboardingState(prevState => ({
				...prevState!,
				run: false,
				tourActive: false
			}));

			return
		}

		if(action === ACTIONS.SKIP) {
			setOnboardingState(prevState => ({
				...prevState!,
				run: false,
				tourActive: false
			}));

			return
		}

		if(action === ACTIONS.CLOSE && lifecycle === 'complete') {
			setOnboardingState(prevState => ({
				...prevState!,
				passedSteps: [...prevState!.passedSteps, index],
				run: false
			}));

			return
		}

		if(action === ACTIONS.NEXT && lifecycle === 'complete') {
			if(step.data?.next) {
				setOnboardingState(prevState => ({
					...prevState!,
					passedSteps: [...prevState!.passedSteps, index],
					activeStepIndex: index + 1
				}));

			
				navigate(step.data.next)

				return
			}

			setOnboardingState(prevState => ({
				...prevState!,
				passedSteps: [...prevState!.passedSteps, index],
				activeStepIndex: index + 1
			}));
			
			return
		}

		if(action === ACTIONS.PREV && lifecycle === 'complete') {
			if(step.data?.previous) {
				setOnboardingState(prevState => ({
					...prevState!,
					passedSteps: prevState!.passedSteps.filter((step) => step !== index - 1),
					activeStepIndex: prevState!.activeStepIndex - 1
				}));

				console.log(step)
				navigate(step.data.previous)

				return
			}

			setOnboardingState(prevState => ({
				...prevState!,
				passedSteps: prevState!.passedSteps.filter((step) => step !== index - 1),
				activeStepIndex: prevState!.activeStepIndex - 1
			}));

			return
		}
	}

    return (
        <Joyride steps={steps} callback={handleClick} run={run} stepIndex={stepIndex} styles={{options: {primaryColor: '#0446f1'}}} continuous showSkipButton />
    )
}
