import React, { useState } from "react"

import Button from "../../../components/Button"
import CircleHalf from "../../../components/CircleHalf"
import { CircleProgress } from "../../../components/CircleProgress"
import Divider from "../../../components/Divider"
import Language from "../../../components/Language"
import PageBlock, { PageBlockTitle } from "../../../components/PageBlock"
import PageTitle from "../../../components/PageTitle"
import Rating from "../../../components/Rating"
import Star from "../../../components/Icons/Star"

import { iconsData } from "./icons"
import { userData } from "./mockData"
import CVIcon from "../../../components/Icons/CVIcon"
import clsx from "clsx"

export default function Profile() {
	const [showAllSkills, setShowAllSkills] = useState(false)
	const [showJobs, setShowJobs] = useState(false)
	const [showAllEdu, setShowAllEdu] = useState(false)
	const [showAllEReviews, setShowAllEReviews] = useState(false)
	const [showAllPReviews, setShowAllPReviews] = useState(false)
	const [showAbout, setShowAbout] = useState(false)
	const jobsL = userData.jobs.map((job) => job.responsibilities.length)
	const [showResp, setShowResp] = useState(Array(jobsL.length).fill(false))

	const JobTag = ({ main = "", text }) => {
		const mainArr = main.toString().split(/\s/g).filter(Boolean)
		return (
			<div
				className="rounded-2xl p-3 flex flex-col"
				style={{ border: "1px solid #EBEFF6" }}
			>
				<div className="flex gap-[6px] items-end">
					<div className="text-[#0446F1] font-medium text-[32px] leading-none">
						{mainArr[0]}
					</div>
					<div className="text-[#0446F1] font-medium text-base leading-normal ">
						{mainArr?.[1] || ""}
					</div>
				</div>
				<div className="text-xs">{text}</div>
			</div>
		)
	}

	const RespTag = ({ children, onClick, className }) => (
		<div
			onClick={() => onClick && onClick()}
			className={clsx("px-3 py-2 flex w-fit", className)}
			style={{
				borderRadius: "51px",
				border: "1px solid #3F74FF",
			}}
		>
			{children}
		</div>
	)

	const Education = ({ data, horizontal }) =>
		data ? (
			<div key={data.name} className="w-full flex px-6">
				<div
					className="bg-contain bg-no-repeat w-[40px] h-[40px] rounded flex self-center mr-3"
					style={{ backgroundImage: `url(${data.src})` }}
				/>
				<div className="flex flex-col w-full py-4 font-medium">
					<div className="flex flex-wrap text-base font-medium items-center">
						{data.name}
						{horizontal && (
							<div className="text-[#939393] text-[13px] ml-1">
								{data.years}
							</div>
						)}
					</div>
					<div className="font-medium text-[#939393] !leading-tight">
						<div>{data.grade}</div>
						{!horizontal && <div>{data.years}</div>}
					</div>
				</div>
			</div>
		) : null

	return (
		<div className="flex flex-col">
			<PageTitle title="Dashboard" showUser />
			<div className="flex gap-4">
				<div className="flex flex-col flex-[1.2] gap-4">
					<PageBlock noPadding>
						<div className="flex w-full gap-4 p-6">
							<div
								className="min-w-[78px] min-h-[78px] bg-no-repeat bg-contain rounded"
								style={{ backgroundImage: `url(${userData.photo})` }}
							/>
							<div className="font-medium flex w-full">
								<div>
									<div className="flex justify-between w-full">
										<div className="text-2xl leading-none mb-1">
											{userData.name}
										</div>
										<div
											className="rounded-xl px-2 py-1 h-fit text-sm leading-none"
											style={{ border: "1px dashed #0446F1" }}
										>
											ID&nbsp;{userData.SSN}
										</div>
									</div>
									<div className="text-base leading-none mb-[6px]">
										{userData.position}
									</div>
									<div className="text-base text-[#A9A9A9] mb-1">
										{userData.place}
									</div>

									<div className="flex items-center">
										<div
											className="w-[20px] h-[20px] bg-contain bg-no-repeat mr-1"
											style={{
												backgroundImage: `url(${userData.EmployerReviews.src})`,
											}}
										/>
										<div className="text-base font-medium">
											{userData.EmployerReviews.place}
										</div>
									</div>
								</div>
							</div>
						</div>
						<Divider />
						<Education data={userData.education[0]} horizontal />
						<Divider />
						<div className="flex justify-between w-full p-6 gap-2">
							<Button variant="bigBlue">Send offer</Button>
							<Button
								variant="mediumBlue"
								onClick={() => window.open(userData.cvLink, "_blank")}
							>
								Open CV <CVIcon />
							</Button>
						</div>
					</PageBlock>

					<PageBlock>
						<div className="flex justify-between w-full">
							<PageBlockTitle>About</PageBlockTitle>
							<Button
								variant="smallBlue"
								onClick={() => setShowAbout((prev) => !prev)}
							>
								{showAbout ? "Hide" : "View"}
							</Button>
						</div>
						<div
							className={clsx(
								"font-medium text-[15px] mt-4 whitespace-pre-wrap",
								!showAbout && "line-clamp-[18]"
							)}
						>
							{userData.about}
						</div>
					</PageBlock>
					<PageBlock noPadding className="!items-start">
						<div className="p-6 pb-5 flex flex-col gap-4">
							<PageBlockTitle>Job history</PageBlockTitle>
							<div className="flex gap-2">
								<JobTag main={userData.jobs.length} text="Jobs" />
								<JobTag
									main={userData.totalWorkTime}
									text="Total operating time"
								/>
								<JobTag
									main={userData.avgWorkTime}
									text="Avr. contract duration"
								/>
							</div>
						</div>
						<Divider />
						{userData.jobs
							?.slice(0, showJobs ? userData.jobs.length : 3)
							.map((job, i, arr) => (
								<React.Fragment key={JSON.stringify(job) + i}>
									<div className="p-6 w-full">
										<div className="flex gap-3">
											<div
												className="bg-no-repeat bg-contain w-[52px] h-[52px]"
												style={{ backgroundImage: `url(${job.src})` }}
											/>
											<div className="font-medium">
												<div>{job.position}</div>
												<div className="flex items-center gap-[6px]">
													<Star rating={job.rating} />
													{job.company}
												</div>
												<div className="text-[13px] text-[#ACACAC]">
													{job.duration
														? `${job.years} · ${job.duration}`
														: job.years}
												</div>
											</div>
										</div>
										<div className="font-medium text-sm text-[#A3A3A3] mt-3 mb-2">
											Responsibilities
										</div>
										<div className="flex flex-wrap gap-1">
											{job?.responsibilities
												?.slice(0, showResp[i] ? jobsL[i] : 3)
												.map((el) => (
													<RespTag key={el}>{el}</RespTag>
												))}
											{jobsL[i] > 3 ? (
												<RespTag
													className="cursor-pointer"
													onClick={() =>
														setShowResp((prev) => {
															const temp = [...prev]
															temp[i] = !temp[i]
															return temp
														})
													}
												>
													{showResp[i] ? "<<" : `+${jobsL[i] - 3}`}
												</RespTag>
											) : null}
										</div>
									</div>
									{(!showJobs || (showJobs && i !== arr.length - 1)) && (
										<Divider />
									)}
								</React.Fragment>
							))}
						{!showJobs &&
							userData?.jobs?.length &&
							userData.jobs.length > 3 && (
								<div
									className="font-medium text-[13px] text-[#0446F1] mx-auto my-4"
									role="button"
									tabIndex={0}
									onClick={() => setShowJobs((prev) => !prev)}
								>
									View all history ({userData.jobs.length})
								</div>
							)}
					</PageBlock>
				</div>

				<div className="flex flex-col flex-1 gap-4">
					<PageBlock className="onboarding_profile_total_score">
						<CircleHalf percent={69} />
					</PageBlock>
					<PageBlock noPadding>
						<div className="flex justify-between w-full mb-2 p-6 pb-0">
							<PageBlockTitle>Employer reviews</PageBlockTitle>
							<Button variant="smallBlue">View</Button>
						</div>
						<div className="w-full px-6 py-3">
							<div className="font-medium text-sm mb-1">HR</div>
							<Rating percent={88} className="mb-[20px]" />
							<div className="font-medium text-sm mb-1">Security</div>
							<Rating percent={63} className="mb-[20px]" />
							<div className="font-medium text-sm mb-1">
								Immediate supervisor
							</div>
							<Rating percent={75} className="mb-[20px]" />
						</div>
						<Divider />
						<div className="w-full p-6 pb-0 flex flex-col">
							<div className="w-full flex items-center mb-4">
								<div
									className="w-[40px] h-[40px] bg-contain bg-no-repeat mr-2"
									style={{
										backgroundImage: `url(${userData.EmployerReviews.src})`,
									}}
								/>
								<div className="font-medium">
									<div className="text-[15px]">
										{userData.EmployerReviews.place}
									</div>
									<div className="text-[13px] text-[#acacac]">
										{userData.EmployerReviews.years}
									</div>
								</div>
							</div>
							<Divider />
							<div className="font-medium my-3 text-base">Last reviews</div>
							{userData?.EmployerReviews?.reviews
								?.slice(
									0,
									showAllEReviews ? userData.EmployerReviews.reviews.length : 3
								)
								.map((el, i, arr) => (
									<div key={i}>
										<div className="flex items-center mb-3">
											<div
												className="bg-contain bg-no-repeat w-[30px] h-[30px] mr-2 rounded"
												style={{ backgroundImage: `url(${el.photo})` }}
											/>
											<div className="font-medium leading-4">
												<div className="text-base">{el.name}</div>
												<div className="text-[13px] flex">
													{el.position}
													<Star rating={el.rating} />
												</div>
											</div>
											<div className="text-[#ACACAC] text-xs font-medium ml-auto">
												{el.years}
											</div>
										</div>
										<div className="font-medium text-sm text-[#979797] line-clamp-2 mb-3">
											{el.text}
										</div>
										{i !== arr.length - 1 && <Divider className="my-2" />}
									</div>
								))}
						</div>
						{!showAllEReviews && <Divider />}
						{!showAllEReviews &&
							userData?.EmployerReviews?.reviews?.length &&
							userData.EmployerReviews.reviews.length > 3 && (
								<div
									className="font-medium text-[13px] text-[#0446F1] mx-auto my-3"
									role="button"
									tabIndex={0}
									onClick={() => setShowAllEReviews((prev) => !prev)}
								>
									View all
								</div>
							)}
					</PageBlock>
					<PageBlock noPadding>
						<PageBlockTitle className="p-6">Skills</PageBlockTitle>
						<Divider />
						{userData.skills
							?.slice(0, showAllSkills ? userData.skills.length : 6)
							.map((el, i, arr) => (
								<div key={el.name} className="w-full">
									<div className="flex flex-col py-4 font-medium">
										<div className="text-base font-medium px-6">{el.name}</div>
										<div className="font-medium text-[13px] text-[#939393] flex gap-1 px-6">
											<img src="/images/tick-green.svg" alt="" />
											Confirmed by {el.confirmed} recommendations
										</div>
									</div>

									{(!showAllSkills ||
										(i !== arr.length - 1 && showAllSkills)) && (
										<Divider className="w-full" />
									)}
								</div>
							))}

						{!showAllSkills &&
							userData?.skills?.length &&
							userData.skills.length > 6 && (
								<div
									className="font-medium text-[13px] text-[#0446F1] mx-auto my-4"
									role="button"
									tabIndex={0}
									onClick={() => setShowAllSkills((prev) => !prev)}
								>
									View all skills ({userData.skills.length})
								</div>
							)}
					</PageBlock>
				</div>
				<div className="flex flex-col flex-1 gap-4">
					<div className="grid gap-1 grid-cols-3 grid-rows-2 onboarding_profile_score_board">
						{iconsData.map((item) => (
							<PageBlock small key={item.name} className="overflow-hidden">
								<img src={`/images/score/${item.icon}.svg`} alt="" />
								<div className="text-[13px] py-1 whitespace-nowrap font-medium text-center">
									{item.name}
								</div>
								<CircleProgress percent={item.percent} />
							</PageBlock>
						))}
					</div>

					<PageBlock noPadding>
						<div className="flex justify-between w-full p-6 pb-3">
							<PageBlockTitle>Peer reviews</PageBlockTitle>
							<Button variant="smallBlue">View</Button>
						</div>
						<div className="px-6 w-full">
							<div className="font-medium text-sm mb-1">
								Professional skills
							</div>
							<Rating percent={95} className="mb-[20px]" />
							<div className="font-medium text-sm mb-1">Responsibility</div>
							<Rating percent={88} className="mb-[20px]" />
							<div className="font-medium text-sm mb-1">
								Communication skills
							</div>
							<Rating percent={70} className="mb-[20px]" />
						</div>
						<Divider />
						<div className="px-6">
							<div className="font-medium my-3 text-base">Last reviews</div>
							{userData?.PeerReviews?.reviews
								?.slice(
									0,
									showAllPReviews ? userData.PeerReviews.reviews.length : 3
								)
								.map((el, i, arr) => (
									<div key={i}>
										<div className="flex items-center mb-3">
											<div
												className="bg-contain bg-no-repeat w-[30px] h-[30px] mr-2 rounded"
												style={{ backgroundImage: `url(${el.photo})` }}
											/>
											<div className="font-medium leading-4">
												<div className="text-base">{el.name}</div>
												<Star rating={el.rating} />
											</div>
											<div className="text-[#ACACAC] text-xs font-medium ml-auto">
												{el.years}
											</div>
										</div>
										<div className="font-medium text-sm text-[#979797] line-clamp-2 mb-3">
											{el.text}
										</div>
										{i !== arr.length - 1 && <Divider className="mb-3" />}
									</div>
								))}
						</div>
						{!showAllPReviews && <Divider className="mt-2" />}
						{!showAllPReviews &&
							userData?.PeerReviews?.reviews?.length &&
							userData.PeerReviews.reviews.length > 3 && (
								<div
									className="font-medium text-[13px] text-[#0446F1] mx-auto my-3"
									role="button"
									tabIndex={0}
									onClick={() => setShowAllPReviews((prev) => !prev)}
								>
									View all
								</div>
							)}
					</PageBlock>

					<PageBlock noPadding>
						<PageBlockTitle className="p-6">Education</PageBlockTitle>
						<Divider />
						{userData.education
							?.slice(0, showAllEdu ? userData.education.length : 3)
							.map((el, i, arr) => (
								<React.Fragment key={i}>
									<Education data={el} />
									{(!showAllEdu || (i !== arr.length - 1 && showAllEdu)) && (
										<Divider className="w-full" />
									)}
								</React.Fragment>
							))}
						{!showAllEdu &&
							userData?.skills?.length &&
							userData.skills.length > 6 && (
								<div
									className="font-medium text-[13px] text-[#0446F1] mx-auto my-4"
									role="button"
									tabIndex={0}
									onClick={() => setShowAllEdu((prev) => !prev)}
								>
									View info about Education ({userData.education.length})
								</div>
							)}
					</PageBlock>

					<PageBlock>
						<PageBlockTitle>Languages</PageBlockTitle>
						<div className="flex flex-wrap gap-4 mt-4">
							{userData.languages?.map((el) => (
								<Language {...el} key={el.language} />
							))}
						</div>
					</PageBlock>
				</div>
			</div>
		</div>
	)
}
